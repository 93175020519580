/**
 * @param str rgba(51, 51, 51, 0.2) 0px 2px 8px -2px
 */
const parseBoxShadow = str => {
    const parts = str.split(/\s(?![^(]*\))/)

    const color = parts[0]

    const numbers = parts
        .filter((part, index) => index > 0)
        .map(string => parseFloat(string))

    const [ offset_x, offset_y, blur_radius, spread_radius, ] = numbers

    return {
        color,
        offset_x,
        offset_y,
        blur_radius,
        spread_radius,
    }
}

export default parseBoxShadow