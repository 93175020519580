<template>
    <div class="app-table" ref="table">
        <div class="actions-dropdown" v-if="actions.row"
            :style="actions.styles"

            ref="actions-dropdown"
        >
            <button class="btn" v-for="(action, key) in actions.list" :key="`actions-${ key }`"
                :class="[ action.class ]"

                @click="onActionClick(action, actions.row)"
            >{{ action.title }}</button>
        </div>

        <div class="select-dropdown"
            :class="{ opened: is_select_dropdown_opened }"
            :style="select_dropdown.styles"

            ref="select-dropdown"
        >
            <div class="options">
                <div class="option"
                    v-for="(option, key) in select_dropdown.options" :key="`option-${ key }`"

                    @click="select(option)"
                >{{ option.title }}</div>
            </div>
        </div>

        <div class="app-table-box"
            :class="{ loading, clickable, 'with-checkboxes': with_checkboxes, collapsible, collapsed: is_collapsed }"
            ref="table-box"
        >
            <slot name="loader">
                <app-loader v-if="loading"></app-loader>
            </slot>

            <div class="header-box">
                <div class="header" v-if="with_header">
                    <h2 class="heading" v-if="title">{{ title }}</h2>

                    <div class="sorting"
                        v-if="with_mobile_sorting"

                        :class="{ opened: sorting.opened }"
                        ref="sorting"
                    >
                        <div class="direction" :class="{ desc }" @click="toggleSortingDirection"></div>

                        <span class="title">Sort by:</span>

                        <div class="toggle" @click.stop="toggleSortingDropdown">
                            <div class="option">{{ sort_option.title }}</div>
                        </div>

                        <div class="options" :style="sorting.options_styles">
                            <div class="option" v-for="(option, index) in sortOptions" :key="index" @click="selectSortingField(option)">{{ option.title }}</div>
                        </div>
                    </div>

                    <div class="header-actions">
                        <slot name="header-actions"></slot>
                    </div>
                </div>

                <template v-if="collapsible">
                    <div v-if="!with_header">&nbsp;</div>
                    <button class="btn btn-collapsing" @click="collapsed = !collapsed"></button>
                </template>
            </div>

            <div class="sub-header" v-show="is_expanded">
                <div class="actions-container" v-if="with_checkboxes">
                    <app-checkbox
                        :value="are_all_checkboxes_selected"
                        :partially-checked="at_least_one_checkbox_selected && !are_all_checkboxes_selected"

                        @change="toggleCheckboxes"

                        @click.stop
                    >{{ at_least_one_checkbox_selected_globally ? `Selected ${ total_of_selected_checkboxes }` : 'Select all' }}</app-checkbox>

                    <div class="sub-header-actions">
                        <slot name="sub-header-actions"></slot>
                    </div>
                </div>
            </div>

            <div class="description" v-if="description" v-show="is_expanded">{{ description }}</div>

            <template v-if="is_mobile_mode">
                <div class="data" v-show="is_expanded">
                    <div class="item" :class="{ disabled: isRowDisabled(row) }" v-for="(row, row_key) in rows" :key="row_key"
                        @click="onRowClickMobile(row)"
                    >
                        <template v-for="(i, index) in mobile_columns.counter">
                            <div class="line" :key="`line-${ row_key }-${ index }`" v-if="!isRowHidden(row, mobile_columns.default[index], index)">
                                <template v-if="mobile_columns.default[index]">
                                    <template v-if="with_checkboxes">
                                        <template v-if="mobile_columns.default[index].type == 'checkbox'">
                                            <div class="col" :key="`col-${ row_key }-${ mobile_columns.default[index].key }`" v-if="with_head">
                                                <app-checkbox
                                                    :value="isChecked(row[selectedKey])"
                                                    @change="toggleCheckbox(row[selectedKey])"
                                                    @click.stop
                                                >{{ mobile_columns.default[index].title }}</app-checkbox>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col with-padding" :key="`col-${ row_key }-${ mobile_columns.default[index].key }`" v-if="with_head">{{ mobile_columns.default[index].title }}</div>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="col" :key="`col-${ row_key }-${ mobile_columns.default[index].key }`" v-if="with_head">{{ mobile_columns.default[index].title }}</div>
                                    </template>


                                    <div class="row" :key="`row-${row_key}-${mobile_columns.default[index].key}`">
                                        <template v-if="(mobile_columns.default[index].action || mobile_columns.default[index].actions) && mobile_columns.default[index].type != 'select'">
                                            <button class="btn btn-table-action"
                                                :class="actionClasses(col)"
                                                :disabled="isActionDisabled(row[mobile_columns.default[index].key])"

                                                @click.stop="onActionClick(col, row, $event)"
                                            ></button>
                                        </template>

                                        <template v-else-if="mobile_columns.default[index].attention || mobile_columns.default[index].type == 'attention'">
                                            <span class="attention" v-if="row[mobile_columns.default[index].key] && row[mobile_columns.default[index].key].show">
                                                <!-- {{ row[mobile_columns.default[index].key].attention || mobile_columns.default[index].attention }} -->
                                            </span>
                                        </template>

                                        <template v-else-if="row[mobile_columns.default[index].key]">
                                            <template v-if="mobile_columns.default[index].type">

                                                <div class="select-trigger" v-if="mobile_columns.default[index].type == 'select'"
                                                    :class="{ opened: isSelectTriggerOpened(mobile_columns.default[index], row) }"

                                                    @click.stop="toggleSelectDropdown(mobile_columns.default[index], row, $event)"
                                                >{{ getSelectTriggerTitle(row[mobile_columns.default[index].key], mobile_columns.default[index]) }}<i></i></div>

                                                <div class="text" v-else-if="mobile_columns.default[index].type == 'phone'"
                                                    :class="{ highlight: mobile_columns.default[index].highlight }"
                                                ><a onclick="false" :href="'tel:' + row[mobile_columns.default[index].key]">{{ row[mobile_columns.default[index].key] }}</a></div>

                                                <div class="status" v-else-if="mobile_columns.default[index].type == 'status'"
                                                    :class="row[mobile_columns.default[index].key].status"
                                                >{{ row[mobile_columns.default[index].key].text }}</div>

                                                <div class="text" v-else-if="mobile_columns.default[index].type == 'clickable'"
                                                    :class="{ clickable: row[mobile_columns.default[index].key].event }"
                                                    @click="onTextClick(row[mobile_columns.default[index].key], row)"
                                                >{{ row[mobile_columns.default[index].key].text }}</div>

                                                <a class="btn btn-table-action" v-else-if="mobile_columns.default[index].type == 'link'"
                                                    :class="actionClasses(col)"
                                                    :href="row[mobile_columns.default[index].key]"
                                                    :target="mobile_columns.default[index].blank ? '_blank' : false"
                                                ></a>

                                                <router-link class="btn btn-table-action" v-else-if="mobile_columns.default[index].type == 'route'"
                                                    :class="actionClasses(col)"
                                                    :to="routeParams(mobile_columns.default[index].route, row[mobile_columns.default[index].key])"
                                                    :target="mobile_columns.default[index].blank ? '_blank' : false"
                                                ></router-link>

                                                <router-link class="router-link" v-else-if="mobile_columns.default[index].type == 'router-link'"
                                                    :to="routeParams(mobile_columns.default[index].route, row[mobile_columns.default[index].key])"
                                                    :target="mobile_columns.default[index].blank ? '_blank' : false"
                                                >{{ row[mobile_columns.default[index].key].text }}</router-link>

                                                <template v-else-if="mobile_columns.default[index].type == 'tags'">
                                                    <template v-if="row[mobile_columns.default[index].key]">
                                                        <template v-if="row[mobile_columns.default[index].key].length < max_mobile_tags">
                                                            <div class="tags">
                                                                <ul>
                                                                    <li v-for="(tag, tag_key) in row[mobile_columns.default[index].key]" :key="`tag-${row_key}-${mobile_columns.default[index].key}-${tag_key}`">{{ tag }}</li>
                                                                </ul>
                                                            </div>
                                                        </template>

                                                        <template v-else>
                                                            <div class="tags">
                                                                <ul :class="{ continued: row[mobile_columns.default[index].key].length > max_mobile_tags }">
                                                                    <template v-for="index_tag in max_mobile_tags">
                                                                        <li v-if="row[mobile_columns.default[index].key][index_tag - 1]"
                                                                            :key="`tag-${row_key}-${mobile_columns.default[index].key}-${index_tag}`"
                                                                        >{{ row[mobile_columns.default[index].key][index_tag - 1] }}</li>
                                                                    </template>
                                                                </ul>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </template>

                                                <div class="image" v-else-if="mobile_columns.default[index].type == 'image'">
                                                    <i :class="[ row[mobile_columns.default[index].key].class ]"></i>
                                                    <span>{{ row[mobile_columns.default[index].key].text }}</span>
                                                </div>

                                                <div class="text" v-else
                                                    :class="{ highlight: mobile_columns.default[index].highlight }"
                                                >{{ row[mobile_columns.default[index].key] }}</div>
                                            </template>

                                            <div class="text" v-else
                                                :class="{ highlight: mobile_columns.default[index].highlight }"
                                            >{{ row[mobile_columns.default[index].key] }}</div>
                                        </template>

                                        <div v-else></div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col" v-if="with_head"></div>
                                    <div class="row"></div>
                                </template>

                                <div class="detached" :key="`detached-${row_key}-${index}`" v-if="mobile_detached_columns_count">
                                    <template v-if="mobile_columns.detached[index]">
                                        <button class="btn btn-table-action" v-if="mobile_columns.detached[index].action || mobile_columns.detached[index].actions"
                                            :class="actionClasses(mobile_columns.detached[index])"
                                            :disabled="isActionDisabled(row[mobile_columns.detached[index].key])"

                                            @click.stop="onActionClick(mobile_columns.detached[index], row, $event)"
                                        ></button>

                                        <template v-else-if="row[mobile_columns.detached[index].key]">
                                            <a class="btn btn-table-action" v-if="mobile_columns.detached[index].type == 'link'"
                                                :class="actionClasses(mobile_columns.detached[index])"
                                                :href="row[mobile_columns.detached[index].key]"
                                                :target="mobile_columns.detached[index].blank ? '_blank' : false"
                                            ></a>

                                            <router-link class="btn btn-table-action" v-else-if="mobile_columns.detached[index].type == 'route'"
                                                :class="actionClasses(mobile_columns.detached[index])"
                                                :to="routeParams(mobile_columns.detached[index].route, row[mobile_columns.detached[index].key])"
                                                :target="mobile_columns.detached[index].blank ? '_blank' : false"
                                            ></router-link>

                                            <template v-else-if="mobile_columns.detached[index].type == 'indicator'">
                                                <div class="indicator" :class="mobile_columns.detached[index].icon" v-if="row[mobile_columns.detached[index].key].show">
                                                    <div class="tooltip" v-if="mobile_columns.detached[index].tooltip">{{ mobile_columns.detached[index].tooltip }}</div>
                                                </div>
                                            </template>

                                            <div class="text" v-else
                                                :class="{ highlight: mobile_columns.detached[index].highlight }"
                                            >{{ row[mobile_columns.detached[index].key] }}</div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>

            <template v-else>
                <div class="head" v-if="with_head" v-show="is_expanded">
                    <div class="col" v-for="(col, key) in cols" :key="`head-${ key }`">
                        <template v-if="col.sort">
                            <div class="sortable" @click="toggleSortingField(col.sort)">
                                <span class="title">{{ col.title }}</span>
                                <span class="order" :class="{ desc }" v-if="sortValue.field == col.sort.field"></span>
                            </div>
                        </template>

                        <template v-else>{{ col.title }}</template>
                    </div>
                </div>

                <div class="rows" v-show="is_expanded">
                    <div class="row" v-for="(row, row_key) in rows" :key="row_key"
                        :class="{ selected: isRowSelected(row), disabled: isRowDisabled(row) }"
                        @click="onRowClick(row)"
                    >
                        <div class="col" v-for="col in cols" :key="`col-${row_key}-${col.key || col.action}`"
                            :class="[ col.class, col.multiline ? 'multiline' : '', col.truncated ? 'truncated' : '' ]"
                        >
                            <template v-if="(col.action || col.actions) && col.type != 'select'">
                                <button class="btn btn-table-action"
                                    :class="actionClasses(col)"
                                    :disabled="isActionDisabled(row[col.key])"

                                    @click.stop="onActionClick(col, row, $event)"
                                ><span v-if="col.text">{{ col.text }}</span></button>
                            </template>

                            <template v-else-if="col.attention || col.type == 'attention'">
                                <span class="attention" v-if="row[col.key] && row[col.key].show">
                                    <!-- {{ row[col.key].attention || col.attention }} -->
                                </span>
                            </template>

                            <template v-else-if="row[col.key] || row[col.key] === 0 || (col.type == 'yesno') "><!-- this is hiding falsey values except 0 if valid wouldn't show at all -->
                                <template v-if="col.type">
                                    <app-checkbox v-if="col.type == 'checkbox'"
                                        :value="isChecked(row[selectedKey])"

                                        @change="toggleCheckbox(row[selectedKey])"

                                        @click.stop
                                    >{{ row[col.key] }}</app-checkbox>

                                    <div class="select-trigger" v-else-if="col.type == 'select'"
                                        :class="{ opened: isSelectTriggerOpened(col, row) }"

                                        @click.stop="toggleSelectDropdown(col, row, $event)"
                                    >{{ getSelectTriggerTitle(row[col.key], col) }}<i></i></div>

                                    <div class="details" v-else-if="col.type == 'details'">
                                        <div class="detail"
                                            v-for="(detail, detail_key) in row[col.key]"
                                            :key="`detail-${row_key}-${col.key}-${detail_key}`"
                                        >
                                            <span class="title" v-if="row[col.key][detail_key].title">{{ row[col.key][detail_key].title }}</span>

                                            <app-code
                                                v-if="row[col.key][detail_key].type == 'code'"

                                                :collapsed="true"
                                                :code="row[col.key][detail_key].data.code"
                                                :title="row[col.key][detail_key].data.title"
                                            />

                                            <div class="word" v-else-if="row[col.key][detail_key].type == 'word'">{{ row[col.key][detail_key].data.text }}</div>
                                        </div>
                                    </div>

                                    <template v-else-if="col.type == 'indicator'">
                                        <div class="indicator" :class="col.icon" v-if="row[col.key].show">
                                            <div class="tooltip" v-if="col.tooltip">{{ col.tooltip }}</div>
                                        </div>
                                    </template>

                                    <div class="status" v-else-if="col.type == 'status'"
                                        :class="row[col.key].status"
                                    >{{ row[col.key].text }}</div>

                                    <div class="text" v-else-if="col.type == 'clickable'"
                                        :class="{ clickable: row[col.key].event }"
                                        @click="onTextClick(row[col.key], row)"
                                    >{{ row[col.key].text }}</div>

                                    <div class="text" v-else-if="col.type == 'phone'"
                                        :class="row[col.key].phone"
                                    ><a onclick="false" :href="'tel:' + row[col.key]">{{ row[col.key] }}</a></div>

                                    <a class="btn btn-table-action" v-else-if="col.type == 'link'"
                                        :class="actionClasses(col)"
                                        :href="row[col.key]"
                                        :target="col.blank ? '_blank' : false"
                                    ></a>

                                    <router-link class="btn btn-table-action" v-else-if="col.type == 'route'"
                                        :class="actionClasses(col)"
                                        :to="routeParams(col.route, row[col.key])"
                                        :target="col.blank ? '_blank' : false"
                                    ></router-link>

                                    <router-link class="router-link" v-else-if="col.type == 'router-link'"
                                        :to="routeParams(col.route, row[col.key])"
                                        :target="col.blank ? '_blank' : false"
                                    >{{ row[col.key].text }}</router-link>

                                    <template v-else-if="col.type == 'tags'">
                                        <div class="tags" ref="tags">
                                            <ul ref="tags_ul">
                                                <li v-for="(tag, tag_key) in row[col.key]" :key="`tag-${row_key}-${col.key}-${tag_key}`">{{ tag }}</li>
                                            </ul>
                                        </div>
                                        <i>...</i>
                                    </template>
                                    
                                    <div v-else-if="col.type == 'datenanos'">
                                        {{formatDateNano(row[col.key])}}
                                    </div>

                                    <div v-else-if="col.type == 'yesno'">
                                        {{row[col.key] ? 'Yes' : 'No'}}
                                    </div>

                                    <div class="image" v-else-if="col.type == 'image'">
                                        <i :class="[ row[col.key].class ]"></i>
                                        <span>{{ row[col.key].text }}</span>
                                    </div>

                                    <div class="text" v-else
                                        :class="{ highlight: col.highlight }"
                                    >{{ row[col.key] }}</div>
                                </template>

                                <div class="text" v-else
                                    :class="{ highlight: col.highlight }"
                                >{{ row[col.key] }}</div>
                            </template>

                            <div v-else>&nbsp;</div>
                        </div>

                        <div class="overlay"></div>
                    </div>
                </div>
            </template>
        </div>

        <div class="actions" v-if="route || with_actions" v-show="is_expanded">
            <router-link :to="{ name: route }" v-if="route">See all</router-link>

            <span v-if="!route"></span>
            <div class="btns" v-if="with_actions">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appCheckbox from '@/components/app-checkbox'
import appCode from '@/components/app-code'

import formatDateNano from '@/helpers/format-date-nano'
import parseBoxShadow from '@/helpers/parse-box-shadow'

const behaviors = {
    'hidden': {
        special: true,
    },
    'detached': {
        special: true,
    },
    'optional': {
        special: false,
    },
}

/**
 * @todo
 * Remove data type "datenanos"
 */

export default {
    props: {
        value: { type: Object },

        title: { type: String, default: null },
        description: { type: String, default: null },

        cols: { type: Array, required: true },
        rows: { type: Array, required: true },

        loading: { type: Boolean, default: false },

        clickable: { type: Boolean, default: false },
        selectedRow: { type: [Number, String, Object], default: null },
        selectedKey: { type: String, default: 'uuid' },

        actionsKey: { type: String, default: 'actions' },

        disabledKey: { type: String, default: 'disabled' },

        sortValue: { type: Object, default: () => ({}) },
        sortOptions: { type: Array, default: () => ([]) },

        mobileMode: { type: Boolean, default: false },

        route: { type: String, default: null },

        collapsible: { type: Boolean, default: false },
        collapsedDefault: { type: Boolean, default: true },
    },

    components: {
        appLoader,
        appCheckbox,
        appCode,
    },

    data() {
        return {
            sorting: {
                opened: false,

                options_styles: {},
            },

            mobile_mode: false,
            max_mobile_tags: 3,

            actions: {
                row: null,
                list: [],

                styles: {},
                target: null,
            },

            select_dropdown: {
                keys: {
                    row: null,
                    col: null,
                },

                options: [],
                action: null,

                styles: {},
                target: null,
            },

            collapsed: false,
        }
    },

    created() {
        this.collapsed = this.collapsible && this.collapsedDefault
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        formatDateNano,
        onRowClick(row) {
            this.$emit('row-click', row)
        },
        onRowClickMobile(row) {
            this.$emit('row-click-mobile', row)
        },
        onActionClick(col, row, $event) {
            if (col.action) {
                this.$emit(col.action, row)
                this.closeActionsDropdown()
            } else if (this.actions.row === row) {
                this.closeActionsDropdown()
            } else {
                this.showActionsDropdown(col, row, $event)
            }
        },
        onTextClick(col, row) {
            if (col.event) {
                this.$emit(col.event, row)
            }
        },
        showActionsDropdown(col, row, $event) {
            const actions = row[this.actionsKey] || col.actions

            if (actions && actions.length) {
                this.actions.row = row
                this.actions.list = actions

                this.actions.target = $event.target

                this.renderActionsDropdown()

                const table_box = this.$refs['table-box']
                table_box.addEventListener('scroll', this.renderActionsDropdown)
                window.addEventListener('resize', this.renderActionsDropdown)

                document.addEventListener('click', this.handleClickOutsideActionsDropdown)
            }
        },
        closeActionsDropdown() {
            if (this.actions.row) {
                this.actions.row = null
                this.actions.list = []

                this.actions.styles = {}
                this.actions.target = null

                const table_box = this.$refs['table-box']
                table_box.removeEventListener('scroll', this.renderActionsDropdown)
                window.removeEventListener('resize', this.renderActionsDropdown)

                document.removeEventListener('click', this.handleClickOutsideActionsDropdown)
            }
        },
        renderActionsDropdown() {
            this.$nextTick(() => {
                const table_rect = this.$refs.table.getBoundingClientRect()
                const target_rect = this.actions.target.getBoundingClientRect()
                const actions_dropdown = this.$refs['actions-dropdown']
                const actions_dropdown_rect = actions_dropdown.getBoundingClientRect()

                const table_width = table_rect.width
                const actions_dropdown_width = actions_dropdown_rect.width
                const actions_dropdown_height = actions_dropdown_rect.height

                const top = target_rect.top - table_rect.top + target_rect.height
                const right = table_rect.right - target_rect.right

                const { offset_x, offset_y, blur_radius, spread_radius, } = parseBoxShadow(getComputedStyle(actions_dropdown).getPropertyValue('box-shadow'))

                const top_shadow    = Math.min(0, offset_y - blur_radius - spread_radius)
                const right_shadow  = Math.max(0, offset_x + blur_radius + spread_radius)
                const bottom_shadow = Math.max(0, offset_y + blur_radius + spread_radius)
                const left_shadow   = Math.min(0, offset_x - blur_radius - spread_radius)

                const right_shadow_width = Math.abs(right_shadow)
                const left_shadow_width = Math.abs(left_shadow)

                let right_overlap_width = Math.abs(right - right_shadow_width < 0 ? right - right_shadow_width : 0)
                right_overlap_width = Math.min(right_overlap_width, left_shadow_width + actions_dropdown_width + right_shadow_width)

                let left_overlap_width = table_width - ( right + actions_dropdown_width + left_shadow_width )
                left_overlap_width = left_overlap_width < 0 ? Math.abs(left_overlap_width) : 0
                left_overlap_width = Math.min(left_overlap_width, left_shadow_width + actions_dropdown_width + right_shadow_width)

                const clip_rect = {
                    top: 0 + top_shadow,

                    right: actions_dropdown_width + right_shadow - right_overlap_width,

                    bottom: actions_dropdown_height + bottom_shadow,

                    left: 0 + left_shadow + left_overlap_width,
                }

                this.actions.styles = {
                    top: `${ top }px`,
                    right: `${ right }px`,
                    clip: `rect(${clip_rect.top}px, ${clip_rect.right}px, ${clip_rect.bottom}px, ${clip_rect.left}px)`,
                }
            })
        },

        toggleSortingDirection() {
            this.$emit('sort-change', {
                ...this.sortValue,
                order: this.sortValue.order == 'asc' ? 'desc' : 'asc',
            })
        },
        toggleSortingDropdown() {
            this.sorting.opened = !this.sorting.opened

            if (this.sorting.opened) {
                document.addEventListener('click', this.handleClickOutsideSorting)
            } else {
                document.removeEventListener('click', this.handleClickOutsideSorting)
            }
        },
        closeSortingDropdown() {
            this.sorting.opened = false

            document.removeEventListener('click', this.handleClickOutsideSorting)
        },
        selectSortingField(option) {
            this.$emit('sort-change', {
                ...this.sortValue,
                field: option.field,
            })

            this.closeSortingDropdown()
        },
        toggleSortingField({ field }) {
            let order = this.sortValue.order

            if (this.sortValue.field == field) {
                order = order == 'asc' ? 'desc' : 'asc'
            } else {
                order = 'asc'
            }

            this.$emit('sort-change', {
                field,
                order,
            })
        },
        calculateOptionsStyles() {
            const table = this.$el.getBoundingClientRect()
            const sorting = this.$refs.sorting.getBoundingClientRect()

            this.sorting.options_styles = {
                top: `${ (sorting.top - table.top) + sorting.height }px`,

                ...(this.title ?
                    { right: `${ table.right - sorting.right }px` } :
                    { left: `${ sorting.left - table.left }px` }
                )
            }
        },

        actionClasses(col) {
            const icon = col.icon || col.action || (col.actions && 'actions')

            let priority = col.priority

            if (!col.priority) {
                switch(icon) {
                    case 'remove':
                    case 'invoice':
                    case 'ext-link':
                    case 'actions':
                    case 'chart': {
                        priority = 'secondary'
                    } break

                    // case 'link':
                    // case 'refresh':
                    default: {
                        priority = 'primary'
                    }
                }
            }

            const action_classes = [
                `action-${ icon }`,
                `action-${ priority }`,
            ]

            if (col.text && !this.mobile_mode) {
                action_classes.push('with-text')
            }

            return action_classes
        },
        isActionDisabled(action) {
            return action && Boolean(action.disabled)
        },
        routeParams(main, additional) {
            const params = {
                ...main,
                ...additional,
            }

            return params
        },

        isRowSelected(row) {
            return this.selected_row != null && this.selected_row == row[this.selectedKey]
        },

        isRowDisabled(row) {
            return row[this.disabledKey]
        },

        handleWindowResize() {
            this.mobile_mode = document.body.clientWidth <= this.$mobile_size

            if (this.with_tags && 'tags' in this.$refs) {
                for (let i = 0, tags_len = this.$refs.tags.length; i < tags_len; i++) {

                    const tags = this.$refs.tags[i]
                    const ul = this.$refs.tags_ul[i]

                    if (tags.offsetWidth >= ul.offsetWidth) {
                        tags.classList.remove('clip')
                    } else {
                        const tags_rect = tags.getBoundingClientRect()
                        const li = ul.querySelectorAll('li')

                        for (let j = 0, li_len = li.length; j < li_len; j++) {
                            if (j > 0) {
                                const tag = li[j]
                                const tag_rect = tag.getBoundingClientRect()

                                if (tag_rect.right > tags_rect.right) {
                                    tag.classList.add('clipped')
                                } else {
                                    tag.classList.remove('clipped')
                                }
                            }
                        }

                        tags.classList.add('clip')
                    }
                }
            }

            if (this.with_mobile_sorting) {
                this.$nextTick(() => {
                    this.calculateOptionsStyles()
                })
            }
        },
        watchingResize(iteration = 5) {
            if (iteration > 1) {
                const width = this.$el.offsetWidth

                setTimeout(() => {
                    this.watchingResize( Math.floor(Math.abs(this.$el.offsetWidth - width)) ? 5 : iteration - 1 )
                }, 50)
            }

            this.handleWindowResize()
        },

        handleClickOutsideSorting(event) {
            if (!(this.$refs.sorting && this.$refs.sorting.contains(event.target))) {
                this.closeSortingDropdown()
            }
        },
        handleClickOutsideActionsDropdown(event) {
            if (!(this.$refs['actions-dropdown'] && this.$refs['actions-dropdown'].contains(event.target))) {
                this.closeActionsDropdown()
            }
        },

        isRowHidden(row, col, index) {
            return this.is_mobile_mode
                && index >= this.mobile_detached_columns_count
                && col.behavior == 'optional'
                && !row[col.key]
        },

        isChecked(key) {
            return key in this.value
        },

        toggleCheckbox(key) {
            if (key in this.value) {
                this.$delete(this.value, key)
            } else {
                this.$set(this.value, key, true)
            }

            this.$emit('input', this.value)
            this.$emit('change', this.value)
        },

        toggleCheckboxes() {
            const keys = this.keys
            const len = keys.length

            if (this.are_all_checkboxes_selected) {
                for (let i = 0; i < len; i++) {
                    this.$delete(this.value, keys[i])
                }
            } else {
                for (let i = 0; i < len; i++) {
                    this.$set(this.value, keys[i], true)
                }
            }

            this.$emit('input', this.value)
            this.$emit('change', this.value)
        },

        select(option) {
            this.$emit(this.select_dropdown.action, {
                [this.select_dropdown.keys.row]: option.value,
            })

            this.closeSelectDropdown()
        },

        renderSelectDropdown() {
            this.$nextTick(() => {
                const table_rect = this.$refs.table.getBoundingClientRect()
                const target_rect = this.select_dropdown.target.getBoundingClientRect()
                const select_dropdown = this.$refs['select-dropdown']
                const select_dropdown_rect = select_dropdown.getBoundingClientRect()

                const table_width = table_rect.width
                const select_dropdown_width = select_dropdown_rect.width
                const select_dropdown_height = select_dropdown_rect.height

                const top = target_rect.top - table_rect.top + target_rect.height
                const right = table_rect.right - target_rect.right

                const { offset_x, offset_y, blur_radius, spread_radius, } = parseBoxShadow(getComputedStyle(select_dropdown).getPropertyValue('box-shadow'))

                const top_shadow    = Math.min(0, offset_y - blur_radius - spread_radius)
                const right_shadow  = Math.max(0, offset_x + blur_radius + spread_radius)
                const bottom_shadow = Math.max(0, offset_y + blur_radius + spread_radius)
                const left_shadow   = Math.min(0, offset_x - blur_radius - spread_radius)

                const right_shadow_width = Math.abs(right_shadow)
                const left_shadow_width = Math.abs(left_shadow)

                let right_overlap_width = Math.abs(right - right_shadow_width < 0 ? right - right_shadow_width : 0)
                right_overlap_width = Math.min(right_overlap_width, left_shadow_width + select_dropdown_width + right_shadow_width)

                let left_overlap_width = table_width - ( right + select_dropdown_width + left_shadow_width )
                left_overlap_width = left_overlap_width < 0 ? Math.abs(left_overlap_width) : 0
                left_overlap_width = Math.min(left_overlap_width, left_shadow_width + select_dropdown_width + right_shadow_width)

                const clip_rect = {
                    top: 0 + top_shadow,

                    right: select_dropdown_width + right_shadow - right_overlap_width,

                    bottom: select_dropdown_height + bottom_shadow,

                    left: 0 + left_shadow + left_overlap_width,
                }

                this.select_dropdown.styles = {
                    top: `${ top }px`,
                    right: `${ right }px`,
                    clip: `rect(${clip_rect.top}px, ${clip_rect.right}px, ${clip_rect.bottom}px, ${clip_rect.left}px)`,
                }
            })
        },

        toggleSelectDropdown(col, row, $event) {
            if (this.select_dropdown.keys.row == row[this.selectedKey] && this.select_dropdown.keys.col == col.key) {
                this.closeSelectDropdown()
            } else {
                this.select_dropdown.keys = {
                    row: row[this.selectedKey],
                    col: col.key,
                }

                this.select_dropdown.options = col.options
                this.select_dropdown.action = col.action

                this.select_dropdown.target = $event.target

                this.renderSelectDropdown()

                const table_box = this.$refs['table-box']
                table_box.addEventListener('scroll', this.renderSelectDropdown)
                window.addEventListener('resize', this.renderSelectDropdown)

                document.addEventListener('click', this.handleClickOutsideSelectDropdown)
            }
        },

        closeSelectDropdown() {
            this.select_dropdown.keys = {
                row: null,
                col: null,
            }

            this.select_dropdown.options = []
            this.select_dropdown.action = null

            this.select_dropdown.styles = {}
            this.select_dropdown.target = null

            const table_box = this.$refs['table-box']
            table_box.removeEventListener('scroll', this.renderSelectDropdown)
            window.removeEventListener('resize', this.renderSelectDropdown)

            document.removeEventListener('click', this.handleClickOutsideSelectDropdown)
        },

        handleClickOutsideSelectDropdown(event) {
            if (!(this.$refs['select-dropdown'] && this.$refs['select-dropdown'].contains(event.target))) {
                this.closeSelectDropdown()
            }
        },

        isSelectTriggerOpened(col, row) {
            return this.select_dropdown.keys.row == row[this.selectedKey]
                && this.select_dropdown.keys.col == col.key
        },

        getSelectTriggerTitle(value, col) {
            const options = col.options

            const option = options
                ? (
                    Array.isArray(options)
                        ? options
                        : Object.values(options)
                ).find(item => item.value == value)
                : null

            return option ? option.title : ''
        },
    },
    
    watch: {
        rows() {
            this.$nextTick(this.handleWindowResize)
        },

        with_mobile_sorting() {
            if (this.with_mobile_sorting) {
                this.$nextTick(() => {
                    this.calculateOptionsStyles()
                })
            } else {
                this.closeSortingDropdown()
            }
        },

        mobile_mode() {
            this.closeSelectDropdown()
        },
    },
    
    computed: {
        ...mapGetters([
            '$mobile_size',
        ]),

        keys() {
            const keys = []

            for (let i = 0, len = this.rows.length; i < len; i++) {
                if (!this.rows[i][this.disabledKey]) {
                    keys.push(this.rows[i][this.selectedKey])
                }
            }

            return keys
        },

        are_all_checkboxes_selected() {
            let result = false

            if (this.value) {
                const keys = this.keys
                const len = keys.length

                let counter = 0

                for (let i = 0; i < len; i++) {
                    if (this.value[keys[i]]) {
                        counter++
                    }
                }

                if (counter == len && len > 0) {
                    result = true
                }
            }

            return result
        },

        at_least_one_checkbox_selected() {
            let result = false

            if (this.value) {
                const keys = this.keys
                const len = keys.length
    
                for (let i = 0; i < len; i++) {
                    if (this.value[keys[i]]) {
                        result = true
                        break
                    }
                }
            }

            return result
        },

        at_least_one_checkbox_selected_globally() {
            let total = 0

            if (this.value) {
                total = Object.keys(this.value).length
            }

            return total > 0
        },

        total_of_selected_checkboxes() {
            let total = 0

            if (this.value) {
                total = Object.keys(this.value).length
            }

            return total
        },

        is_select_dropdown_opened() {
            return Boolean(this.select_dropdown.keys.row)
                && Boolean(this.select_dropdown.keys.col)
        },

        selected_row() {
            return this.selectedRow ? this.selectedRow[ this.selectedKey ] || this.selectedRow : null
        },

        is_sort_available() {
            return this.cols.findIndex(col => col.sort) > -1
        },

        sort_option() {
            return this.sortOptions.find(option => option.field == this.sortValue.field) || this.sortOptions[0]
        },

        desc() {
            return this.sortValue.order == 'desc'
        },

        with_tags() {
            return this.cols.findIndex(col => col.type == 'tags' && this.rows.findIndex(row => Array.isArray(row[col.key]) && row[col.key].length) > -1) > -1
        },

        is_mobile_mode() {
            return this.mobileMode || this.mobile_mode
        },

        mobile_columns() {
            let mobile_columns = {
                default: [],
                detached: [],
                counter: 0,
            }

            let with_positioning_detaching = false

            if (this.is_mobile_mode) {
                for (let i = 0, len = this.cols.length; i < len; i++) {
                    const col = this.cols[i]

                    if (col.behavior && col.behavior in behaviors && behaviors[col.behavior].special) {
                        if (col.behavior && col.behavior == 'detached') {
                            mobile_columns.detached.push(col)

                            if ('detached_position' in col) {
                                with_positioning_detaching = true
                            }
                        }
                    } else {
                        mobile_columns.default.push(col)
                    }
                }

                mobile_columns.counter = Math.max(
                    mobile_columns.default.length,
                    mobile_columns.detached.length
                )

                if (with_positioning_detaching) {
                    const count = mobile_columns.detached.length

                    if (count > 1) {
                        for (let i = 0; i < count; i++) {
                            const column = mobile_columns.detached[i]

                            if ('detached_position' in column) {
                                let index = parseInt(column.detached_position)

                                if (!isNaN(index) && index > 0 && --index != i) {
                                    const relocatable = mobile_columns.detached.splice(index, 1, column)
                                    mobile_columns.detached.splice(i, 1, relocatable[0])
                                }
                            }
                        }
                    }
                }
            }

            return mobile_columns
        },

        mobile_detached_columns_count() {
            return this.mobile_columns.detached.length
        },

        with_header() {
            return this.title || this.with_mobile_sorting || this.with_checkboxes
        },

        with_mobile_sorting() {
            return this.is_mobile_mode && this.is_sort_available
        },

        with_checkboxes() {
            return this.cols.findIndex(col => col.type == 'checkbox') > -1
        },

        with_head() {
            return this.with_checkboxes
                || this.cols.findIndex(col => col.title) > -1
        },

        with_actions() {
            return Boolean(this.$slots.actions)
        },

        is_collapsed() {
            return this.collapsible && this.collapsed
        },

        is_expanded() {
            return !this.is_collapsed
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)

        document.removeEventListener('click', this.handleClickOutsideSorting)
        document.removeEventListener('click', this.handleClickOutsideActionsDropdown)
        document.removeEventListener('click', this.handleClickOutsideSelectDropdown)

        const table_box = this.$refs['table-box']

        table_box.removeEventListener('scroll', this.renderSelectDropdown)
        window.removeEventListener('resize', this.renderSelectDropdown)

        table_box.removeEventListener('scroll', this.renderActionsDropdown)
        window.removeEventListener('resize', this.renderActionsDropdown)
    },
}
</script>

<style lang="scss">
.app-table {
    position: relative;
    background-color: var(--color-table-row-bg);
    border-radius: $border-radius-secondary;
    box-shadow: var(--box-shadow-secondary);

    .app-table-box {
        overflow: hidden;

        .app-checkbox {
            &:before {
                margin-right: #{16px - 3px};
            }

            span {
                color: var(--color-table-primary);
            }
        }

        .header,
        .sub-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding: 0 24px;

            &:empty,
            &>:empty:not(.btn) {
                display: none;
            }
        }

        .header-box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-collapsing {
                flex-shrink: 0;

                width: 24px;
                margin: 12px 16px 12px 0;
                background-color: var(--color-table-row-bg);

                @include icon-before($icon-chevron-top);
            }
        }

        .header {
            flex-grow: 1;
            max-width: 100%;

            h2 {
                margin: 16px 0;

                & ~ .sorting {
                    margin: 0 0 0 auto;
                }
            }

            .sorting {
                display: flex;
                align-items: center;
                margin: 16px 0;

                .direction {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;

                    color: var(--color-icon-calm);

                    @include icon-before($icon-arrow-bottom);

                    &.desc {
                        transform: rotateX(180deg);
                    }

                    &:hover {
                        &::before {
                            color: var(--color-table-action-primary);
                        }
                    }
                }

                .title {
                    margin-left: 12px;
                    margin-right: 16px;

                    font-size: 18px;

                    color: var(--color-table-tertiary);
                }

                .toggle {
                    padding: 12px 0;
                    cursor: pointer;

                    .option {
                        position: relative;

                        width: fit-content;

                        padding-right: 32px;

                        font-size: 18px;
                        line-height: 1.33;

                        color: var(--color-text-default);

                        user-select: none;

                        &::after {
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: block;
                            width: 26px;
                            height: 26px;

                            color: var(--color-icon-calm);

                            @include icon($icon-chevron-bottom, 26px);
                        }

                        &:hover {
                            color: var(--color-table-action-primary);

                            &::after {
                                color: var(--color-table-action-primary);
                            }
                        }
                    }
                }

                .options {
                    position: absolute;

                    z-index: $z-index-dropdown;

                    display: none;

                    min-width: 178px;

                    padding: 8px 0 8px;

                    background-color: var(--color-dropdown-bg);
                    border-radius: $border-radius-primary;
                    box-shadow: var(--box-shadow-primary);

                    .option {
                        margin-bottom: 16px;
                        padding: 4px 24px;

                        font-size: 18px;
                        line-height: 1.33;

                        color: var(--color-text-default);

                        cursor: pointer;

                        user-select: none;

                        @include text-overflow();

                        &:hover {
                            background-color: var(--color-dropdown-bg-hover);
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                &.opened {
                    .toggle {
                        .option {
                            color: var(--color-table-action-primary);

                            &::after {
                                color: var(--color-table-action-primary);
                                transform: rotateX(180deg);
                            }
                        }
                    }

                    .options {
                        display: block;
                    }
                }
            }

            .header-actions {
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: wrap;

                .btn {
                    width: 170px;
                    max-width: 100%;
                    margin: 12px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &:empty {
                    display: none;
                }
            }
        }

        .sub-header {
            .actions-container {
                display: flex;
                margin: 24px 0;

                .app-checkbox {
                    flex-shrink: 0;

                    font-size: 18px;
                }

                .sub-header-actions {
                    display: flex;
                    margin-left: 108px;

                    .btn {
                        display: flex;
                        width: auto;
                        height: 24px;
                        margin-right: 30px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .description {
            padding: 0 24px 12px;
            font-size: 20px;
            line-height: 24px;

            &:first-child {
                padding-top: 24px;
            }
        }

        .head {
            display: flex;

            height: 72px;

            padding: 0 $app-table-column-padding;

            color: var(--color-table-head);
            background-color: var(--color-table-head-bg);

            .col {
                display: flex;
                align-items: center;

                padding: 0 $app-table-column-padding;

                font-size: 16px;
                font-weight: var(--app-table-head-font-weight);
                line-height: 24px;

                text-transform: uppercase;

                .sortable {
                    display: flex;
                    align-items: center;

                    cursor: pointer;
                    user-select: none;

                    .order {
                        width: 24px;
                        height: 24px;

                        color: var(--color-icon-calm);

                        @include icon-before($icon-chevron-bottom);

                        &.desc {
                            transform: rotateX(180deg);
                        }
                    }

                    &:hover {
                        color: var(--color-table-action-primary);

                        .order {
                            &::before {
                                color: var(--color-table-action-primary);
                            }
                        }
                    }
                }
            }
        }

        .rows {
            .row {
                position: relative;

                display: flex;
                flex-wrap: nowrap;

                padding: 18px $app-table-column-padding;

                .col {
                    display: flex;
                    align-items: center;

                    padding: 0 $app-table-column-padding;
                }

                .overlay {
                    position: absolute;

                    top: -1px;
                    left: 0;
                    right: 0;
                    bottom: -1px;

                    z-index: 1;

                    display: none;

                    background-color: var(--color-table-row-bg-hover);
                }

                &.selected {
                    background-color: var(--color-table-row-bg-active);

                    &:first-child {
                        &::before {
                            position: absolute;

                            left: 24px;
                            right: 24px;
                            top: 0;

                            display: block;

                            height: 1px;

                            background-color: var(--color-divider);

                            content: "";
                        }
                    }

                    .col {
                        z-index: 2;
                    }

                    .overlay {
                        display: block;

                        background-color: var(--color-table-row-bg-active);
                    }
                }

                &.disabled {
                    pointer-events: none;

                    .col {
                        opacity: 0.4;
                    }
                }

                &::after {
                    position: absolute;

                    left: 24px;
                    right: 24px;
                    bottom: 0;

                    display: block;

                    height: 1px;

                    background-color: var(--color-divider);

                    content: "";
                }
            }
        }

        .row,
        .detached {
            font-size: 16px;
            line-height: 24px;
            color: var(--color-table-secondary);

            .text {
                @include text-overflow();

                &.highlight {
                    color: var(--color-table-primary);
                }

                &.clickable {
                    color: var(--color-table-action-primary);

                    // text-decoration: underline;

                    cursor: pointer;

                    transition: $transition-duration-primary;

                    &:not([disabled]) {
                        &:hover {
                            color: var(--color-table-action-primary-hover);
                        }

                        &:active {
                            color: var(--color-table-action-primary-active);
                        }
                    }
                }

                a {
                    color: inherit;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .col {
                &.multiline,
                &.truncated:hover {
                    .text {
                        word-break: break-word;
                        white-space: normal;
                        overflow: visible;
                    }
                }
            }

            .select-trigger {
                position: relative;

                min-width: 140px;

                padding-right: #{24px * 1.5};

                cursor: pointer;

                user-select: none;

                @include text-overflow();

                i {
                    position: absolute;
                    top: 0;
                    right: 0;

                    display: block;

                    width: 24px;
                    height: 24px;

                    color: var(--color-icon-calm);

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;

                        @include icon($icon-chevron-bottom, 24px);
                    }
                }

                &:hover {
                    i {
                        color: var(--color-table-action-primary);
                    }
                }

                &.opened {
                    i {
                        @include icon-before-code($icon-chevron-top);
                    }
                }
            }

            .details {
                display: flex;
                flex-wrap: wrap;

                margin-bottom: -$app-table-column-padding;

                .detail {
                    flex-shrink: 0;

                    display: flex;
                    align-items: center;

                    margin-right: 32px;
                    margin-bottom: $app-table-column-padding;

                    & > .title {
                        flex-shrink: 0;

                        margin-right: 16px;
                        color: var(--color-table-primary);
                        text-transform: uppercase;
                    }

                    & > .app-code {
                        width: 540px;
                    }

                    & > .word {
                        flex-shrink: 0;

                        color: var(--color-table-primary);
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .indicator {
                position: relative;

                width: 24px;
                height: 24px;

                color: var(--color-btn-primary);
                background-color: var(--color-btn-primary-bg);

                border-radius: $border-radius-primary;

                .tooltip {
                    position: absolute;

                    top: calc(100% + 8px + (14px - 8px));
                    left: 50%;

                    transform: translateX(-50%);

                    z-index: 1;

                    display: none;

                    padding: 8px 12px;

                    font-size: 14px;
                    line-height: 16px;

                    color: var(--color-btn-primary-bg-hover);

                    background-color: var(--color-component-bg-primary);

                    border-radius: $border-radius-primary;

                    box-shadow: $box-shadow-primary;

                    white-space: nowrap;

                    &::after {
                        position: absolute;

                        bottom: 100%;
                        left: 50%;

                        margin: 0 0 0 -5px;

                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent transparent var(--color-component-bg-primary) transparent;

                        content: '';
                    }
                }

                &:hover {
                    .tooltip {
                        display: block;
                    }
                }

                @include icon-before($icon-table-action-default);

                @each $type, $icon in $icons-table-actions {
                    &.#{ $type } {
                        @include icon-before-code($icon);
                    }
                }
            }

            .status {
                padding: 4px 12px;

                font-weight: bold;

                text-align: center;

                border-radius: $border-radius-primary;

                // white-space: nowrap;

                @each $type in $table-tag-types {
                    &.#{ $type } {
                        color: map-get($table-tag-colors, $type);
                        background-color: map-get($table-tag-backgrounds, $type);
                    }
                }
            }

            .router-link {
                color: var(--color-table-action-primary);

                text-decoration: underline;

                transition: $transition-duration-primary;

                &:not([disabled]) {
                    &:hover {
                        color: var(--color-table-action-primary-hover);
                    }

                    &:active {
                        color: var(--color-table-action-primary-active);
                    }
                }
            }

            .btn-table-action {
                &.action-link,
                &.action-remove {
                    margin-left: auto;
                }
            }

            .tags {
                position: relative;

                width: 100%;

                margin-right: 12px;

                color: var(--color-table-primary);
                font-weight: bold;

                overflow: hidden;

                & + i {
                    flex-shrink: 0;

                    display: none;

                    font-size: 16px;
                    font-weight: bold;
                    line-height: 24px;
                }

                ul {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    width: fit-content;

                    li {
                        margin-right: 14px;
                        padding: 4px 12px;

                        border-radius: $border-radius-primary;
                        background: var(--color-table-tag);

                        white-space: nowrap;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                &.clip {
                    & + i {
                        display: block;
                    }

                    li {
                        &.clipped {
                            visibility: hidden;
                        }
                    }
                }
            }
        }

        .row {
            &:last-child {
                .indicator {
                    .tooltip {
                        top: auto;
                        bottom: calc(100% + 8px + (14px - 8px));

                        &::after {
                            top: 100%;
                            bottom: auto;

                            border-color: var(--color-component-bg-primary) transparent transparent transparent;
                        }
                    }
                }
            }
        }

        .data {
            .item {
                font-size: 16px;
                line-height: 24px;
                color: var(--color-table-secondary);

                margin: 0 #{$app-table-column-padding + $app-table-column-padding};
                padding: 8px 0;
                border-bottom: 1px solid var(--color-divider);

                .line {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    margin: 8px 0;

                    & > * {
                        padding: 0 8px;

                        &:first-child {
                            padding-left: 0;
                        }

                        &:last-child {
                            padding-right: 0;
                        }
                    }

                    &:empty {
                        display: none;
                    }

                    .col {
                        text-transform: uppercase;
                        white-space: normal;
                        color: var(--color-table-primary);
                    }

                    .row {
                        flex-grow: 1;

                        .text {
                            white-space: normal;
                            word-break: break-word;
                        }

                        /*
                            & > a,
                            & > button {
                                    margin: 4px 0;
                            }
                        */
                    }

                    .detached {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        flex-shrink: 0;

                        height: 24px;

                        margin: 0;
                    }

                    &.bilinear {
                        .row {
                            .text {
                                @include line-clamp(24px, 2);
                            }
                        }
                    }
                }

                &.disabled {
                    pointer-events: none;

                    .line {
                        opacity: 0.4;
                    }
                }
            }
        }

        .row,
        .data {
            .image {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                overflow: hidden;

                i {
                    flex-shrink: 0;
                }

                span {
                    @include text-overflow();
                }
            }
        }

        .attention {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            color: var(--color-table-secondary);

            @include icon-before($icon-attention);

            &:before {
                color: var(--color-table-primary);
            }
        }

        &.loading {
            position: relative;
        }

        &.clickable {
            .rows {
                .row {
                    cursor: pointer;

                    .col {
                        z-index: 2;
                    }

                    &:hover {
                        .overlay {
                            display: block;
                        }
                    }
                }
            }
        }

        &.collapsible {
            .header {
                max-width: calc(100% - #{ 24px + 16px })
            }
        }

        &.collapsed {
            .header-box {
                .btn-collapsing {
                    @include icon-before-code($icon-chevron-bottom);
                }
            }
        }

        &:last-child {
            .rows {
                .row {
                    &:last-child {
                        &:after {
                            content: none;
                        }
                    }
                }
            }

            .data {
                .item {
                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }

    .actions-dropdown {
        position: absolute;
        z-index: 2;
        min-width: 180px;
        margin-top: 8px;
        padding: 8px 0;
        background-color: var(--color-autocomplete-bg);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-primary);
        overflow: auto;

        .btn {
            align-items: left;
            justify-content: flex-start;
            height: 32px;
            margin: 14px 0 0;
            padding: 0 24px;
            font-size: 18px;
            color: var(--color-table-action-secondary);
            border-radius: 0;
            text-transform: none;

            &:first-child {
                margin: 0;
            }

            &:hover {
                background: var(--color-autocomplete-option-hover-bg);
            }

            &.danger {
                color: var(--color-table-action-primary);
            }
        }
    }

    .select-dropdown {
        $item-height: 32px;
        $item-line-height: 24px;
        $item-margin: 7px;

        position: absolute;

        z-index: 1;

        min-width: 140px;

        margin-top: 4px;

        background-color: var(--color-autocomplete-bg);
        border-radius: $border-radius-secondary;
        box-shadow: var(--box-shadow-primary);

        .options {
            .option {
                height: $item-height;

                margin: $item-margin 0;
                padding: #{($item-height - $item-line-height) / 2} 24px;

                line-height: $item-line-height;
                font-size: 18px;

                color: var(--color-autocomplete-option);

                cursor: pointer;

                user-select: none;

                @include text-overflow();

                &:hover {
                    background: var(--color-autocomplete-option-hover-bg);
                }
            }
        }
    }
    
    .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        position: relative;

        a {
            flex-shrink: 0;
            position: relative;

            padding-right: 32px;

            font-size: 18px;
            color: var(--color-text-default);

            white-space: nowrap;

            &::after {
                position: absolute;

                top: 50%;
                right: 0;
                transform: translateY(-50%);

                display: block;

                width: 24px;
                height: 24px;

                color: var(--color-icon-calm);

                @include icon($icon-arrow-right);
            }

            &:hover {
                color: var(--color-table-action-primary);

                &::after {
                    color: var(--color-table-action-primary);
                }
            }
        }

        .btns {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 8px;

            .btn {
                width: 210px;
                margin: 0 8px;

                &:first-child { margin-left: 0; }
                &:last-child { margin-right: 0; }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-table {
        .app-table-box {
            .header,
            .sub-header {
                padding: 0 16px;
            }

            .header-box {
                .btn-collapsing {
                    margin-right: 12px;
                }
            }

            .sub-header {
                .actions-container {
                    .sub-header-actions {
                        margin-left: 56px;
                    }
                }
            }

            .description {
                font-size: 18px;
                line-height: 22px;
                padding: 0 16px 12px;
            }

            .head {
                padding: 0;

                .col {
                    padding: 0 8px;

                    font-size: 14px;
                    line-height: 24px;
                }
            }

            .rows {
                .row {
                    padding: 18px 0;

                    .col {
                        padding: 0 8px;
                    }

                    &:after {
                        left: 8px;
                        right: 8px;
                    }
                }
            }

            .row,
            .detached {
                font-size: 14px;

                .app-checkbox {
                    font-size: inherit;
                }

                .status {
                    padding: 2px 8px;

                    font-size: 12px;
                }

                .tags {
                    ul {
                        li {
                            margin-right: 8px;
                            padding: 4px 8px;
                        }
                    }
                }
            }

            .data {
                .item {
                    font-size: 14px;
                    margin: 0 8px;
                    padding: 4px 0;

                    .line {
                        margin: 4px 0;
                    }
                }
            }

            &.with-checkboxes {
                .header,
                .sub-header {
                    padding: 0 8px;
                }
            }

            &.collapsible {
                .header {
                    max-width: calc(100% - #{ 24px + 12px })
                }
            }
        }

        .actions {
            margin: 0 8px;
            padding: 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-table {
        .app-table-box {
            .app-checkbox {
                &:before {
                    margin-right: #{8px - 3px};
                }
            }

            .header,
            .sub-header {
                padding: 16px 8px 8px;
            }

            .header-box {
                .btn-collapsing {
                    margin-top: 16px + 8px;
                    margin-right: 8px;
                }
            }

            .header {
                h2 {
                    @include text-overflow();
                }

                .sorting {
                    flex-shrink: 0;

                    width: fit-content;

                    font-size: 16px;
                    line-height: 32px;

                    .title {
                        height: 32px;

                        margin: 0 4px 0 8px;

                        font-size: inherit;
                        line-height: inherit;
                    }

                    .toggle {
                        padding: 0;

                        .option {
                            padding-right: 26px;

                            font-size: inherit;
                            line-height: inherit;

                            &:after {
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }

                    &.opened {
                        .toggle {
                            .option {
                                &:after {
                                    transform: translateY(-50%) rotateX(180deg);
                                }
                            }
                        }
                    }
                }

                .header-actions {
                    flex-grow: 0;
                    justify-content: flex-start;
                }
            }

            .sub-header {
                .actions-container {
                    width: 100%;

                    margin: 16px 0;

                    .app-checkbox {
                        font-size: 16px;
                    }

                    .sub-header-actions {
                        margin-left: auto;

                        .btn {
                            margin-right: 18px;

                            span {
                                display: none;
                            }
                        }
                    }
                }
            }

            .description {
                font-size: 14px;
                line-height: 16px;
                padding: 0 8px 8px;

                &:first-child {
                    padding-top: 16px;
                }
            }

            .row {
                line-height: 20px;

                .tags {
                    ul {
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        li {
                            margin: 0 0 4px 0;
                            padding: 4px 8px;

                            &:last-child {
                                margin: 0;
                            }
                        }

                        &.continued {
                            li {
                                &:last-child {
                                    position: relative;

                                    &:after {
                                        content: "...";
                                        position: absolute;
                                        right: -16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .data {
                .item {
                    .line {
                        .col {
                            font-size: 12px;
                        }

                        .detached {
                            .indicator {
                                .tooltip {
                                    top: 50%;
                                    left: auto;
                                    right: calc(100% + 8px + (14px - 8px));

                                    transform: translateY(-50%);

                                    &::after {
                                        top: 50%;
                                        left: 100%;
                                        bottom: auto;

                                        margin: -5px 0 0 0;

                                        border-color: transparent transparent transparent var(--color-component-bg-primary);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .attention {
                align-items: center;
            }

            &.with-checkboxes {
                .header {
                    flex-wrap: wrap;
                }

                .data {
                    .item {
                        .line {
                            .col {
                                .app-checkbox {
                                    font-size: 12px;
                                }

                                &.with-padding {
                                    padding-left: #{24px + 8px - 3px};
                                }
                            }
                        }
                    }
                }
            }

            &.collapsible {
                .header {
                    max-width: calc(100% - #{ 24px + 8px })
                }
            }
        }

        .actions-dropdown {
            padding: 0;

            .btn {
                height: 4px + 20px + 4px;
                margin: 8px 0;
                font-size: 14px;

                &:first-child {
                    margin-top: 8px;
                }
            }
        }

        .actions {
            flex-wrap: wrap-reverse;
            padding: 16px 8px;

            a {
                font-size: 16px;
                line-height: 18px;
                margin: 16px 0 0;

                &:only-child {
                    margin: 0;
                }
            }

            .btns {
                width: 100%;
                flex-wrap: wrap;
                margin: 0;

                .btn {
                    width: 100%;
                    margin: 4px 0;

                    &:first-child { margin-top: 0; }
                    &:last-child { margin-bottom: 0; }
                }
            }
        }
    }
}
</style>